<script>
/* eslint-disable  */
</script>
<template>
  <b-card>
    <ejs-grid
      id="overviewgrid"
      ref="overviewgrid"
      locale="it-IT"
      :data-source="pluRepositories"
      :selection-settings="selectionSettings"
      :edit-settings="editSettings"
      :filter-settings="filterOptions"
      :show-column-chooser="false"
      :allow-selection="true"
      :allow-sorting="true"
      :allow-filtering="true"
      :allow-resizing="true"
      :enable-virtualization="false"
      :text-wrap-settings="wrapSettings"
      height="600"
      :allow-pdf-export="true"
      :allow-excel-export="true"
      row-height="38"
      :enable-hover="false"
      :enable-header-focus="true"
      :allow-grouping="false"
      :allow-paging="true"
      :toolbar="toolbar"
      :toolbar-click="toolbarClick"
      :page-settings="pageSettings"
      :context-menu-items="contextMenuItems"
      :context-menu-click="contextMenuClick"
      :data-bound="dataBound"
      :command-click="commandColumm"
    >
      <e-columns>
        <!-- <e-column
          type="checkbox"
          :allow-filtering="false"
          :allow-sorting="false"
          width="60"
        /> -->
        <e-column
          field="id"
          :visible="false"
          header-text="ID"
          :is-primary-key="true"
          width="130"
        />
        <e-column
          field="nome"
          header-text="Data"
          width="230"
          :filter="filter"
        />
        <e-column
          field="invio"
          header-text="Inviato alle bilance"
          width="150"
          :filter="filter"
          :template="templateInvio"
          clip-mode="EllipsisWithTooltip"
        />
        <e-column
          field="esito"
          header-text="messaggio"
          width="900"
          :template="templateEsito"
          clip-mode="EllipsisWithTooltip"
        />
        <!-- <e-column
          field="Azioni"
          :lock-column="true"
          header-text="Azioni"
          width="240"
          :template="cTemplate"
          :allow-editing="false"
          :commands="commands"
          :allow-sorting="false"
          :allow-filtering="false"
        /> -->
      </e-columns>
    </ejs-grid>
  </b-card>
</template>
<script>
import Vue from 'vue'
import {
  GridPlugin, Sort, Filter, Selection, PdfExport, ExcelExport, Edit, Page, ContextMenu, CommandColumn, Resize, Scroll, Toolbar,
} from '@syncfusion/ej2-vue-grids'
import {
  BCard, BBadge, BAvatar,
} from 'bootstrap-vue'
import moment from 'moment'
import { L10n, setCulture } from '@syncfusion/ej2-base'
import { italian } from '@/@core/grid-translation/it-IT'
import { getPluiRepository } from '@/@core/api-service/devices/Plu'

setCulture('it-IT')
L10n.load(italian)
Vue.use(GridPlugin)

export default {
  components: {
    BCard,
    BBadge,
    BAvatar,
  },
  props: {

  },
  data() {
    return {
      wrapSettings: { wrapMode: 'Content' },
      options: {'weekday': 'long', 'month': '2-digit', 'day': '2-digit'},
      show: false,
      pluRepositories: [],
      commands: [
        { type: 'Elimina', id: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
        { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
        {
          type: 'CaricaPrezzi', id: 'CaricaPrezzi', target: '.e-column', buttonOption: { iconCss: 'e-icons e-download', cssClass: 'e-flat' },
        },
      ],
      contextMenuItems: ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending',
        'Copy', 'Save',
        'FirstPage', 'PrevPage',
        'LastPage', 'NextPage', 'Group', 'Ungroup',
        /* {
          text: 'CaricaPrezzi', target: '.e-content', id: 'CaricaPrezzi', iconCss: 'e-icons e-download',
        },
        {
          text: 'Elimina', tooltipText: 'Delete', iconCss: 'e-icons e-delete', id: 'DeleteCustom',
        }, */
      ],
      pageSettings: { pageSize: 25, pageSizes: [25, 50, 100, 200], pageCount: 5 },
      showModalSettingPdfExport: false,
      editSettings: {
        allowFiltering: true, allowEditing: false, allowAdding: true, allowDeleting: true, mode: 'Normal',
      },
      toolbar: ['ExcelExport', 'PdfExport', 'CsvExport'
        /* {
          text: 'Elimina', tooltipText: 'Elimina', prefixIcon: 'e-delete', id: 'Elimina',
        }, */
      ],
      toolbarOptions: ['ExcelExport'],
      filterOptions: {
        type: 'Menu',
      },
      filter: {
        type: 'CheckBox',
      },
      selectionSettings: { persistSelection: false, type: 'Multiple', checkboxOnly: true },
      templateEsito() {
        return {
          template: Vue.component('templateEsito', {
            components: {
            },
            data() { return { data: {} } },
            computed: {
              esitoComputed() {
                // console.log(this.data)
                if (this.data.esito) {
                  const data = this.data.esito
                  const dataInizio = data.substring(0,20)
                  const dataFine = data.substring(29, 49)
                  const restoStringa = data.substring(49).replace(/\r?\n|\r/g, " ")
                  // const restoStringa1 = restoStringa.replace('?', 'è')
                  const restoStringa1 = restoStringa.split('errori.')
                  const restoMapped =  restoStringa1.map(el => {
                    const erroreStringToAppend = 'errori.'
                    el = el.concat(erroreStringToAppend)
                    el = el.replace('?', 'è')
                    return el
                  })
                  // restoMapped.pop()
                  return [{ dataInizio: dataInizio, dataFine: dataFine, }, restoMapped]
                }
                return [{ ...this.data }]
              },
            },
            template: `
            <div class="my-2">
              <p v-for="(esitoBilancia, index) in esitoComputed[1]" :key="index">
                <span v-if="esitoComputed[1].length > 1 && index === 0" class="">{{ esitoComputed[0].dataInizio }} </span>
                <span v-else-if="esitoComputed[1].length  < 2" class="">{{ esitoComputed[0].dataInizio }}: </span>
                <span>{{ esitoBilancia }}</span>
              </p>
            </div>
            `,
          }),
        }
      },
      /* <div class="my-2">
              <p v-for="(esitoBilancia, index) in esitoComputed[1]" :key="index">
                <span v-if="esitoComputed[1].length > 1 && index === 0" class="">{{ esitoComputed[0].dataInizio }} </span>
                <span v-else-if="esitoComputed[1].length  < 2" class="">{{ esitoComputed[0].dataInizio }}: </span>
                <span>{{ esitoBilancia }}</span>
              </p>
            </div> */
      templateInvio() {
        return {
          template: Vue.component('templateInvio', {
            components: {
              BAvatar,
            },
            data() { return { data: {} } },
            template: `
            <b-avatar
              :variant="data.invio == 'S' ? 'success' : 'danger'"
              class="mr-2"
            >
              <feather-icon
                :icon="data.invio == 'S' ? 'CheckIcon' : 'XIcon'"
                size="14"
              />
            </b-avatar>`,
          }),
        }
      },
      cTemplate() {
        return {
          template: Vue.component('cTemplate', {
            template: `
              <div class="image">
                <img :src="image" :alt="altImage"/>
              </div>`,
            data() {
              return {
                data: {},
              }
            },
          }),
        }
      },
    }
  },
  mounted() {
    this.openModal()
    /* moment.updateLocale('it', {
      months: 'Gennaio_Febbraio_Marzo_Aprile_Maggio_Giugno_Luglio_Agosto_Settembre_Ottobre_Novembre_Dicembre'.split('_'),
      monthsShort: 'Genn._Feb._Mar_Apr._Mag_Giu_Lug._Ago_Set._Ott._nov._dic.'.split('_'),
      monthsParseExact: true,
      weekdays: 'domenica_lunedi_martedi_mercoledi_giovedi_venerdi_sabato'.split('_'),
      weekdaysShort: 'din._lun._mar._mer._gio._ven._sab.'.split('_'),
    }) */
  },
  methods: {
    openModal() {
      this.show = true
      moment.updateLocale('it', {
        months: 'Gennaio_Febbraio_Marzo_Aprile_Maggio_Giugno_Luglio_Agosto_Settembre_Ottobre_Novembre_Dicembre'.split('_'),
        monthsShort: 'Genn._Feb._Mar_Apr._Mag_Giu_Lug._Ago_Set._Ott._nov._dic.'.split('_'),
        monthsParseExact: true,
        weekdays: 'domenica_lunedi_martedi_mercoledi_giovedi_venerdi_sabato'.split('_'),
        weekdaysShort: 'din._lun._mar._mer._gio._ven._sab.'.split('_'),
      })
      getPluiRepository(JSON.stringify([{ id_puntovendita: null }])).then(res => {
        // console.log(res)
        if (res.data.esito === 'OK') {
          this.pluRepositories = [...res.data.plu]
          this.pluRepositories = JSON.parse(JSON.stringify(this.pluRepositories))
          this.pluRepositories = this.pluRepositories.map(element => ({ ...element, nome: moment(element.nome, 'DD/MM/YYYY HH:mm:ss').add(2, 'h').format('DD/MM/YYYY HH:mm:ss'), repository: JSON.parse(element.repository) }))
          // console.log(this.pluRepositories)moment(element.nome, 'DD/MM/YYYY hh:mm:ss').add(2, 'hour').format('DD/MM/YYYY hh:mm:ss').add(2, 'h')
          this.pluRepositories.forEach(el => {
            if (el.esito) {
              el.esito = el.esito.replaceAll('Rete TransArchOne ------------------------------------------------------------------------------', '/')
            }
          })
          //moment(element.nome, 'DD/MM/YYYY hh:mm:ss').add(2, 'h').format('DD/MM/YYYY hh:mm:ss')
        }
      }).catch(e => e)
    },
    commandColumm(args) {
      if (args.commandColumn.type === 'CaricaPrezzi') {
        this.$emit('load-plu-repository', args.rowData.repository)
        this.$refs['modal-plu-repository-list'].hide()
      }
    },
    contextMenuClick(args) {
      // console.log('contextMenuClick', args)
      if (args.item.id === 'CaricaPrezzi') {
        this.$emit('load-plu-repository', args.rowInfo.rowData.repository)
        this.$refs['modal-plu-repository-list'].hide()
      }
    },
    toolbarClick(args) {
      if (args.item.id === 'Elimina' && this.$refs.overviewgrid.getSelectedRecords().length > 0) {
        // console.log('delete')
        // this.alertAndConfirm()
      }
      if (args.item.id === 'overviewgrid_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
        const exportProperties = {
          // you can customize the name as per your requirement
          fileName: `Stato_Invio_Bilance_${moment().format('DD-MM-YYYY')}.pdf`,
        }
        this.$refs.overviewgrid.pdfExport(exportProperties)
        // this.$refs.overviewgrid.pdfExport(exportProperties)
      }
      if (args.item.id === 'overviewgrid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        const exportProperties = {
          // you can customize the name as per your requirement
          fileName: `Stato_Invio_Bilance_${moment().format('DD-MM-YYYY')}.xlsx`,
        }
        this.$refs.overviewgrid.excelExport(exportProperties)
      }
      if (args.item.id === 'overviewgrid_csvexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        const exportProperties = {
          // you can customize the name as per your requirement
          fileName: `Stato_Invio_Bilance_${moment().format('DD-MM-YYYY')}.csv`,
        }
        this.$refs.overviewgrid.csvExport(exportProperties)
      }
    },
    dataBound() {
      // console.log(this.$refs.overviewgrid)
      this.$refs.overviewgrid.autoFitColumns(['nome'])
    },
    close() {
      this.show = false
    },
  },
  provide: {
    grid: [PdfExport, ExcelExport, Filter, Selection, Sort, Edit, Page, ContextMenu, CommandColumn, Resize, Scroll, Filter, Toolbar],
  },
}
</script>

<style>

</style>
